main.subpage{
  .landing{
    padding-top:11rem;
    padding-bottom:8rem;
    @include media-breakpoint-down(xs) {
      padding-top: 6.5rem;
      padding-bottom: 3.5rem;
    }
  }
  section.feature-wrap{
    .feature{
      @include media-breakpoint-down(xs) {
        ul{
          text-align: left;
        }
      }
    }
  }
}

main.stocktake{
  .landing{
    background-image: $gradient-opaque-dark, url('../images/stocktaking_landing_bg.jpg');
    @include media-breakpoint-down(xs) {
      background-image:$gradient;
    }
  }
}

main.order{
  .landing{
    background-image: $gradient-opaque-dark, url('../images/orders_landing_bg.jpg');
    @include media-breakpoint-down(xs) {
      background-image:$gradient;
    }
  }
}

main.report{
  .landing{
    background-image: $gradient-opaque-dark, url('../images/report_landing_bg.jpg');
    @include media-breakpoint-down(xs) {
      background-image:$gradient;
    }
  }
}
