@import "vendor/vendor";

@import "variables";
@import "scaffolding";

nav{
  background: rgba(14,14,14,0.9);
  transform: translateZ(0);
  font-size: 1rem;
  .navbar-brand{
    cursor: pointer;
    max-width: 130px;
    margin: -2px 0 0;
    padding: 0;
    @include media-breakpoint-down(xs) {
      max-width: 100px;
    }
  }
  ul{
    margin-bottom: 0;
    padding: 0;
    li{
      line-height: 31px;
      font-size: 0.9rem;
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .navbar-brand{
      padding: 0.4rem 0;
    }

    button{
      color: white;
      padding: .2rem .75rem;
      font-size: $font-size-lg;
    }

    ul{
      background-color: black;
      li{
        display: block;
        width: 100%;
        padding: 0.8rem;
        margin: 0 !important;
        text-align: center;
        font-size: 1.5rem;
        border-top: 1px solid #2b2b2b;
      }
    }
  }
}

main{
  @include media-breakpoint-down(sm) {
    h1,h2{
      font-size: 1.5rem;
    }
  }
  section.landing{
    padding-top: 4rem;
    background-image: $gradient-opaque, url('../images/landing_bg.jpg');
    @include media-breakpoint-down(xs) {
      background-image:$gradient;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      padding-top: 6.5rem;
      .col-sm-5{
        padding-right: 1rem;
      }
    }
    .lead{
      text-align: center;
      margin-top: 1rem;
    }
    .products{
      text-align: center;
      img{
        max-width: 90%;
      }
      @include media-breakpoint-up(md) {
        margin-top: 2rem;
      }
      @include media-breakpoint-down(xs) {
        margin-top: 2rem;
        img{
          max-width: 100%;
        }
      }
    }
  }

  section.feature-wrap{
    padding: 5rem 0;
    @include media-breakpoint-down(xs) {
      padding: 2rem 0;
    }
    overflow-x: hidden;
    .feature{
      .benefit{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem 0 2rem;
        @include media-breakpoint-down(xs) {
          padding: 0 1rem 0 1rem;
        }
      }
      .thumbnail{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 46%;
        margin: -1rem 0 calc(-1rem - 20px) 0;
        position: relative;
        @include media-breakpoint-down(sm) {
          max-height: 400px;
          img{
            max-height: 100%;
            position: absolute;
            bottom: 0;
          }
        }
      }
      .thumbnail.right{
        justify-content: flex-start;
      }
      .thumbnail.bottom{
        margin-bottom: -5rem !important;
        @include media-breakpoint-down(xs) {
          margin-bottom: -2rem !important;
        }
      }

      .thumbnail.portrait{
        padding: 0 0 0 2em;
        @include media-breakpoint-down(xs) {
          img{
            max-width: 70% !important;
          }
        }
      }

      @include media-breakpoint-down(xs) {
        .row{
          display: flex;
          text-align: center;
          .benefit{
            order:0;
          }
          .thumbnail{
            order: 1;
            width: 100%;
            display: block;
            margin: auto auto 0 auto;
            img{
              position: relative;
              max-height: none;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  section.feature-wrap.grey{
    background-color: $gray-blue;
  }

  section.testimonial{
    background-image: $gradient-opaque-dark, url('../images/home_testimonial_bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 3rem 0;
    color: $white;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.50);

    @media(max-width:767px){
      br{
        display: block;
      }
    }
    div.profile{
      border-right: 1px solid white;
      margin-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      @media(max-width:767px){
        border-right: none;
        padding-top: 20px;
      }
      img{
        width: 140px;
        height: 140px;
        margin-bottom: 10px;
        border-radius: 300px;
      }
      p{
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        margin-bottom: 0;
        width: 140px;
        text-align: center;
        margin: auto auto 10px;
        span{
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
    div.text{
      display: flex;
      align-items: center;
      h3{
        font-size: 1.6rem;
        font-style: italic;
        @media(max-width:767px){
          text-align: center;
        }
      }
    }
  }

  section.awesome-features{
    div.div-line{
      margin-bottom: 3rem;
    }
    .media-left{
      width: 65px;
      text-align: center;
      img{
        max-width:55px;
      }
      @include media-breakpoint-down(xs) {
        width: 50px;
        img{
          max-width:35px;
        }
      }
    }
    h5{
      font-family: 'FrutigerNext LT Bold';
      font-size: 1.1rem;
      @include media-breakpoint-down(xs) {
        text-align: left;
      }
    }
    p{
      font-size: 0.9rem;
      @include media-breakpoint-down(xs) {
        text-align: left;
      }
    }
  }

  section.beta-wrap{
    color: white;
    background-image: $gradient;
    text-align: center;
    position: relative;
    padding: 4rem 0;
    img.ribbon{
      position: absolute;
      top: 0;
      right: 0;
    }
    h5{
      margin-top: 30px;
    }
    a{
      margin: 1rem 0 0;
    }
    div.div-line{
      background-color: white;
    }
    ul{
      display: inline-block;
      li{
        text-align: left;
      }
    }
    form{
      text-align: center;
      font-size: 20px;
      margin-top: 30px;
      .row{
        margin-bottom: 15px;
      }
      @media(max-width:767px){
        font-size: 16px;
      }
    }
    button{
      width: 20%;
      height: 45px;
      border: 1px solid white;
      border-radius: 6px;
      margin: auto;
      @include media-breakpoint-down(sm) {
        width: 30%;
      }
    }
    input[type="text"],input[type="email"]{
      height: 45px;
      width: 80%;
      background: none;
      border: 1px solid white;
      border-radius: 6px;
      outline: none;
      padding: 5px 10px;
      margin: 0 auto;
      @media(max-width:767px){
        width: 100%;
        padding: 10px;
      }
      @include media-breakpoint-down(xs) {
        margin: 0 15px;
      }
    }
    .row.action{
      margin-bottom: 20px;
      @media(max-width:767px){
        margin-bottom: 0;
      }
    }
  }

}

footer{
  background: rgba(32,32,32,1);
  font-size: 14px;
  padding: 1rem;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

section.dialog{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7788;
  background: rgba(0, 29, 45, 0.85);
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .popup{
    width: 600px;
    max-width: 80%;
    text-align: center;
    background: white;
    border-radius: 3px;
    padding: 40px 40px 20px;
    position: relative;
    .close{
      position: absolute;
      top: 0;
      right: 8px;
    }
  }
}

@import "subpages";
