// Colors
$white: #fff;
$darkGrey: #2B2B2B;
$gray-blue: #EDF1F5;

$blue: #6D97D5;
$orange: #E36666;
$green: #55BDC1;
$yellow: #F4BA29;

$gradient: linear-gradient(-135deg, #4B6CB7 0%, #182848 100%);
$gradient-opaque: linear-gradient(-162deg, rgba(14,59,163,0.68) 0%, rgba(24,40,72,0.46) 100%);
$gradient-opaque-dark: linear-gradient(48deg, rgba(0, 37, 121, 0.94) 0%, rgba(9, 28, 66, 0.68) 100%);
