html {
  font-size: 16px;
}

@include media-breakpoint-up(md) {
  html {
    font-size: 18px;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 20px;
  }
}

@font-face {
  font-family: 'FrutigerNext LT Regular';
  src: url('../fonts/LT_54361.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LT_54361.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LT_54361.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LT_54361.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/LT_54361.svg#a1c1d8086204becf8ac3194da08a1064') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'FrutigerNext LT Bold';
  src: url('../fonts/LT_54363.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LT_54363.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LT_54363.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LT_54363.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/LT_54363.svg#949490a8bcbb53aeec0c9e1e4b8b8e60') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#2B2B2B;
  font-family: 'FrutigerNext LT Regular';
}

h1,h2,.bold{
  font-family: 'FrutigerNext LT Bold';
}

h1{
  font-size: 2rem;
}
h2{
  font-size: 1.8rem;
}

button{
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

.btn{
  background-color: transparent;
  border: 1px solid #2B2B2B;
  color: #2B2B2B;
  border-radius: 0.4rem;
  padding: 0.3rem 1rem;
  font-size: 1rem;
}
a.btn, a.btn:visited{
  color: #2B2B2B;
}

.div-line{
  width: 7rem;
  height: 3px;
  margin: 1rem 0 1.2rem;
  background-color: #2B2B2B;
  display: inline-block;
}

.dark{
  color: white;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.50);
  p{
    text-shadow: 0px 1px 1px rgba(0,0,0,0.50);
  }
  a{
    color: white;
  }
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

a,a:focus,button,button:focus{
  outline: none;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: white;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: white;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
}

.shake{
  animation: shake .3s linear;
}

@-webkit-keyframes shake {
   8%, 41% {
       transform: translateX(-10px);
   }
   25%, 58% {
       transform: translateX(10px);
   }
   75% {
       transform: translateX(-5px);
   }
   92% {
       transform: translateX(5px);
   }
   0%, 100% {
       transform: translateX(0);
   }
}

/*FIXED FIX*/
.fixed-bg {
    background-attachment: fixed !important;
    background-size: cover;
    background-position: center;
}

/*IPAD*/
/* Portrait */
@media only screen
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {
  .fixed-bg {
    background-size: auto 100% !important;
    background-attachment: scroll !important;
    background-position: center;
  }
}

/* Landscape */
@media only screen
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
  .fixed-bg {background-size: auto 100% !important;background-attachment: scroll !important; }
}
